import { graphql } from "gatsby";
import React from "react";
import { androidDocument } from "react-icons-kit/ionicons/androidDocument";
import Fade from "react-reveal/Fade";
import { Flex, Heading } from "rebass";
import CallToAction from "../components/CallToAction";
import IconText from "../components/IconText";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Paragraph from "../components/Paragraph";
import Section from "../components/Section";
import SEO from "../components/Seo";
import MigrationStepSection from "../container/MigrationStepSection";
import NeedToKnowSection from "../container/NeedToKnowSection";
import PropertySection from "../container/PropertySection";

const NeedToKnowPage = ({ data }) => {
  return (
    <Layout transparentHeader>
      <SEO title="移民須知" />

      <PageHeader backgroundImage={data.bg.childImageSharp.fluid}>
        <Fade delay={100}>
          <Heading color="white" fontSize={[5, 7]}>
            移民須知
          </Heading>
        </Fade>
      </PageHeader>

      <Section>
        <Flex flexWrap="wrap">
          <Paragraph width={[1, 1 / 2]} mb={["4rem", "4rem", 0]}>
            <p
              css={`
                font-size: 1.5rem;
                margin-bottom: 1.5rem;
              `}
            >
              <strong>希臘買房移民申請居留需要提供什麼文件？</strong>
            </p>
            <IconText icon={androidDocument}>申請表</IconText>
            <IconText icon={androidDocument}>有效護照的複印件</IconText>
            <IconText icon={androidDocument}>證明入境希臘的文件</IconText>
            <IconText icon={androidDocument}>
              三張近期彩色照片，規格按照護照照片要求
            </IconText>
            <IconText icon={androidDocument}>醫療保險</IconText>
            <IconText icon={androidDocument}>
              房產買賣合同和房產登記證明
            </IconText>
          </Paragraph>

          <Paragraph width={[1, 1 / 2]}>
            <p
              css={`
                font-size: 1.5rem;
                margin-bottom: 1.5rem;
              `}
            >
              <strong>希臘買房移民的家庭成員申請居留需要提供什麼文件？</strong>
            </p>
            <IconText icon={androidDocument}>申請表</IconText>
            <IconText icon={androidDocument}>有效護照的複印件</IconText>
            <IconText icon={androidDocument}>證明入境希臘的文件</IconText>
            <IconText icon={androidDocument}>
              三張近期彩色照片，規格按照護照照片要求
            </IconText>
            <IconText icon={androidDocument}>醫療保險</IconText>
            <IconText icon={androidDocument}>
              房產買賣合同和房產登記證明
            </IconText>
            <IconText icon={androidDocument}>
              證明和購房人親屬關係的家庭關係證明，需雙認證
            </IconText>
          </Paragraph>
        </Flex>
      </Section>

      <PropertySection showButton />

      <MigrationStepSection bg="secondary" />

      <NeedToKnowSection />

      <CallToAction />
    </Layout>
  );
};

export default NeedToKnowPage;

export const query = graphql`
  {
    bg: file(relativePath: { eq: "bg-3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    images: allFile(
      filter: {
        relativePath: { regex: "/need-to-know/" }
        extension: { ne: "md" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
